import React from "react";
import previewTracker from "../images/activity-tracker-preview.webp";
import previewAdventure from "../images/adventure-game-preview.webp";
import previewHoneybee from "../images/villa-honeybee-preview.webp";
import previewCoaching from "../images/fletcher-coaching-preview.webp";

class PortfolioPage extends React.Component {
	render() {
		return <div id="portfolio" className="background background2">
			<div className="portfolio-caption">
				<h1>Portfolio</h1>
				<p>Some of my projects:</p>
				<div id="portfolio-container">
					<div className="portfolio-image">
						<img src={previewCoaching} className="portfolio-preview" alt="..." />
						<a href="https://www.waynefletchercoaching.com/" target="_blank" rel="noopener noreferrer">
							<div className="portfolio-text">
								<h5>Wayne Fletcher Coaching</h5>
								<p>A website for a personal coach.</p>
							</div>
						</a>
					</div>
					
					<div className="portfolio-image">
						<img src={previewHoneybee} className="portfolio-preview" alt="..." />
						<a href="https://www.villahoneybeekalkan.com" target="_blank" rel="noopener noreferrer">
							<div className="portfolio-text">
								<h5>Villa Honeybee</h5>
								<p>A website for a Turkish Villa.</p>
							</div>
						</a>
					</div>
					
					<div className="portfolio-image">
						<img src={previewAdventure} className="portfolio-preview" alt="..." />
						<a href="https://www.tomdaviesdevelopment.com/adventure/" target="_blank" rel="noopener noreferrer">
							<div className="portfolio-text">
								<h5>React Adventure</h5>
								<p>A choose your own adventure style game to be played in a browser, built with React.</p>
							</div>
						</a>
					</div>
					
					<div className="portfolio-image">
						<img src={previewTracker} className="portfolio-preview" alt="..." />
						<a href="https://www.tomdaviesdevelopment.com/tracker/" target="_blank" rel="noopener noreferrer">
							<div className="portfolio-text">
								<h5>DnD Activity Tracker</h5>
								<p>A simple website that helps people track combat in a game of Dungeons and Dragons.</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	};
};

export default PortfolioPage;