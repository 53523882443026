import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import AboutPage from "./Components/AboutPage";
import SkillsPage from "./Components/SkillsPage";
import PortfolioPage from "./Components/PortfolioPage";
import ContactPage from "./Components/ContactPage";

class App extends React.Component {
	render() {
		return <div id="app">
			<BrowserRouter>
				<NavBar newPage={this.changePage}/>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<AboutPage />} />
					<Route path="/skills" element={<SkillsPage />} />
					<Route path="/portfolio" element={<PortfolioPage />} />
					<Route path="/contact" element={<ContactPage />} />
				</Routes>
			</BrowserRouter>
		</div>
	};
};

export default App;