import React from "react";
import logo from "../images/github.png";
import { Link } from 'react-router-dom';

class NavBar extends React.Component {
	toggle = () => {
		let x = document.getElementById("navbarInner");
		if (x.className === "navbarInner") {
			x.className += " responsive";
		} else {
			x.className = "navbarInner";
		};
	};
	
	render() {
		return <div className="navbar">
			<p>Tom Davies Development</p>
			<nav id="navbarInner" className="navbarInner">
				<a href="https://github.com/TomDaviesDev?tab=repositories" id="github-link">My GitHub <img src={logo} alt="GitHub logo" /></a>
				<Link to="/" className="navbarButton">Home</Link>
				<Link to="/about" className="navbarButton">About</Link>
				<Link to="/skills" className="navbarButton">Skills</Link>
				<Link to="/portfolio" className="navbarButton">Portfolio</Link>
				<Link to="/contact" className="navbarButton">Contact</Link>
			</nav>
			<button id="icon" onClick={this.toggle}><i className="fa fa-bars"></i></button>
		</div>
	};
};

export default NavBar;
