import React from "react";
import axios from "axios";

class ContactPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			subject: "",
			content: "",
			validation: "",
			error: null,
			success: false
		};
	};
	
	formSubmit = (event) => {
		event.preventDefault();
		axios({
			method: "post",
			url: "/contactform.php",
			header: {"content-type": "application/json"},
			data: this.state
		})
		.then(result => {
			this.setState({
				success: result.data.sent,
				error: result.data.message
			})
			if (this.state.success === true) {
				this.setState({
					email: "",
					subject: "",
					content: ""
				})
			}
		})
		.catch(error => this.setState({
			error: error.message
		}));
	};
	
	render() {
		return <div id="contact" className="background background3">
			<div className="contact-caption">
				<h1>Contact</h1>
				<p>If you would like to contact me, you can send me a message with the form below.</p>
				
				<div>
					{this.state.success &&
						<div id="successMessage">Thank you, your message has been sent. I will respond to you as soon as possible.</div>
					}
					{this.state.error !== null ? this.state.error !== undefined ?
						<div id="error">{this.state.error}</div>
						: null : null
					}
				</div>
				
				<form method="post" action="contactform.php">
					<div className="form">
						<label htmlFor="email">Email address</label>
						<input type="email" className="form-input" id="email" name="email" placeholder="Enter email" value={this.state.email} onChange={e => this.setState({email: e.target.value})} />
					</div>
					<div className="form">
						<label htmlFor="subject">Subject</label>
						<input type="text" className="form-input" id="subject" name="subject" placeholder="Subject" value={this.state.subject} onChange={e => this.setState({subject: e.target.value})} />
					</div>
					<div className="form">
						<label htmlFor="content">Message</label>
						<textarea className="form-input" id="content" name="content" rows="8" value={this.state.content} onChange={e => this.setState({content: e.target.value})}></textarea>
					</div>
					<div className="form">
						<label htmlFor="validation" className="validation">Validation</label>
						<input type="text" name="validation" id="validation" className="validation" tabIndex="-1" autoComplete="off" value={this.state.validation} onChange={e => this.setState({validation: e.target.value})} />
					</div>
					<input type="submit" id="submit" value="Submit" onClick={e => this.formSubmit(e)}></input>
				</form>
			</div>
		</div>
	};
};

export default ContactPage;
