import React from "react";

class SkillsPage extends React.Component {
	render() {
		return <div id="skills" className="background background2">
			<div className="caption">
				<h1>Technical Stuff</h1>
				<p>I work primarily as a frontend dev, and am highly experienced with HTML5, CSS3 and raw Javascript (as well as jQuery).</p>
				<p>I also work with some backend tech, including PHP, MySQL and I am currently learning NodeJS.</p>
				<br />
				<h3>React</h3>
				<p>React is used to create responsive and well optimized websites and web apps that look good and work smoothly for users.</p>
				<p>I have worked extensively with React since 2019 and am experienced in the use of React itself, as well as several popular frameworks including React Redux, React Router and React Navigation.</p>
			</div>
		</div>
	};
};

export default SkillsPage;
