import React from "react";

class AboutPage extends React.Component {
	render() {
		return <div id="about" className="background background1">
			<div className="caption">
				<h1>About Me</h1>
				<p>My name is Tom. I'm a British born, Denmark based Web Developer. When I moved to Denmark from my native UK I began experimenting with web development as a possible new career direction. Thankfully, I discovered a new passion and have been delving into the world of programming and web development ever since.</p>
				<p>Of course, building websites isn't for everybody. But these days customers expect businesses of all sizes to have a website where they can learn about you, contact you or directly purchase things from you online. Fortunately, this is where I come in. I offer a professional, personal and flexible service to help you create the exact website that you need.</p>
			</div>
		</div>
	};
};

export default AboutPage;
