import React from "react";

class Home extends React.Component {
	render() {
		return <div id="home" className="background background1">	
			<div className="caption">
				<p id="heading">Tom Davies Development</p>
				<p>Websites made simple.</p>
			</div>
		</div>
	};
};

export default Home;
